<template>
    <div style="background:#fff;padding:20px;" :style="{'min-height':(windowHeight-80)+'px'}">
        <div class="group-title">
            <span class="txt">设置密码</span>
        </div>
        <div class="user-setpwd">
            <el-form :model="setpwqForm"  status-icon  :rules="setpwqFormRules" ref="setpwqFormRef" label-width="0">
                <el-form-item label-width="0" prop="tel">
                    <el-input v-model="setpwqForm.tel" placeholder="手机号" disabled  autocomplete="off"  >
                        <template #prepend>
                            <span >中国 +86</span>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label-width="0" prop="verifyCode" style="border: 1px solid #DCDFE6;">
                    <el-input v-model="setpwqForm.verifyCode"  placeholder="6位数字" oninput="value=value.replace(/[^0-9.]/g,'')"  class="input-verifycode" />
                    <div class="vcode-area fr">
                        <el-link :underline="false" href="#" @click.prevent='sendVerifyCodeFun' v-if='isSend' class="vcode-btn">获取验证码</el-link>
                        <el-link :underline="false" disabled v-if='!isSend' class="vcode-btn">{{sendMsg}}</el-link>
                    </div>
                </el-form-item>
                <el-form-item label-width='0'  prop="password">
                    <el-input
                        type="password" placeholder="请输入新密码"
                        v-model="setpwqForm.password"
                        autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label-width='0'  prop="checkPassword">
                <el-input
                    type="password" placeholder="请第二次输入新密码"
                    v-model="setpwqForm.checkPassword"
                    autocomplete="off"></el-input>
                </el-form-item>
                
                <el-form-item label-width='0'>
                    <el-button  style="width: 100%;" type="primary" @click="submitForm()">确定</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import {sendVerifyCode} from "@/http/loginApi";  
import {updatePassword,addUserLog} from '@/http/basicsApi'
import { ElMessage } from 'element-plus'
import { useRoute } from 'vue-router';
export default {
    name:'setpwd',
    components:{
  },
    setup() {
        const route=useRoute();
        const windowHeight =ref(document.documentElement.clientHeight);// 可视区的高度
        const currentUser=JSON.parse( localStorage.getItem('currentUser'));//当前用户信息
        let setpwqForm=ref({
            userId:currentUser.userId,
            tel:currentUser.tel,
            verifyCode:'',
            password:'',
            checkPassword:'',
           
        })
        let setpwqFormRef = ref(null); 
        //验证两次密码是否一致
        let validatePass2 = (rule, value, callback) => { 
            if (value === '') {
                callback(new Error('请再次输入密码')) 
            } else if (value !== setpwqForm.value.password) {
                callback(new Error('两次输入密码不一致!'))
            } else {
                callback()
            }
        };
        let validatePass=(rule, value, callback)=>{
             //var reg = new RegExp("^(?![0-9]+$)(?![a-zA-Z]+$)(?![0-9a-zA-Z]+$)(?![0-9\\W]+$)(?![a-zA-Z\\W]+$)[0-9A-Za-z\\W]{6,18}$", "g");
             var reg =new RegExp("^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!@#$%^&*`~()-+=]+$)(?![a-zA-Z0-9]+$)(?![a-zA-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9\\W_!@#$%^&*`~()-+=]+$)(?![0-9A-Z\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{6,16}$", "g");

             if (value === '') {
                callback(new Error('请输入密码')) 
            } else if (!reg.test(value)) {
                callback(new Error('包含大小写字母、数字、特殊字符'))
            } else {
                callback()
            } 
        }
        
        //注册提交验证
        let setpwqFormRules = {
            
            verifyCode: [
                { required: true, message: "请输入验证码", trigger: "blur" },
                { min: 6, max: 6, message: "长度为6字符", trigger: "blur" },
            ],
            
            password: [
                { required: true, message: "请输入密码", trigger: "blur" },
                { min: 6, max: 16, message: "长度在6-16个字符", trigger: "blur" },
                { validator:validatePass , trigger: "blur" } 
            ],
            checkPassword: [
                { required: true, message: "请第二次输入密码", trigger: "blur" },
                { min: 6, max: 16, message: "长度在6-16个字符", trigger: "blur" },
                { validator:validatePass2 , trigger: "blur" } 
            ],
            
        };
        
        let sendMsg=ref('发送验证码')
        let isSend=ref(true)
        //获取验证码
        const sendVerifyCodeFun=()=>{
            let obj = setpwqFormRef.value;  
            console.log(obj);
            obj.validateField(['tel'],(isValid) => {
                console.log(isValid);
                if(!isValid){
                    console.log(isValid);
                    sendVerifyCode(setpwqForm.value.tel,1)
                    .then((res) => {
                        console.log(res)
                        if ( res.code == 20000) {
                            //Message.success(res.data.msg)
                            isSend.value = false;
                            let timer = 60;
                            sendMsg.value = timer + "s后可重发";
                            const timeFun = setInterval(() => {
                                --timer;
                                sendMsg.value = timer + "s后可重发";
                                if (timer == 0) {
                                    isSend.value = true;
                                    sendMsg.value = "获取短信验证码";
                                    clearInterval(timeFun);
                                }
                            }, 1000);
                        }
                    });
                }
            });
        }
        
        //修改密码
        const submitForm= ()=>{
            let obj = setpwqFormRef.value;  
            obj.validate((isValid) => {
                console.log(isValid);
                if(isValid){
                    let params={
                        userId:setpwqForm.value.userName,
                        tel:setpwqForm.value.tel,
                        verifyCode:setpwqForm.value.verifyCode,
                        password:setpwqForm.value.password,
                        checkPassword:setpwqForm.value.checkPassword
                    }
                    console.log(params);
                    updatePassword(params).then((res)=>{
                        if(res.code==20000){
                            ElMessage.success({
                                message: res.message, 
                            });
                            setpwqForm.value.verifyCode='';
                            setpwqForm.value.password='';
                            setpwqForm.value.checkPassword='';
                        }
                        else{
                            ElMessage.error({
                                message: res.message, 
                            });
                        }
                    });
                }
            });
            //记录用户操作日志
            addUserLog(route.name,route.fullPath,'设置密码',JSON.stringify({userId:currentUser.userId}));
        }
        //记录用户操作日志
        addUserLog(route.name,route.fullPath,'加载',JSON.stringify({userId:currentUser.userId}));
        return{
            windowHeight,
            setpwqForm,
            setpwqFormRef,
            setpwqFormRules,
            sendMsg,
            isSend,
            sendVerifyCodeFun,
            submitForm,
        }
    },
}
</script>
<style  lang="scss" scoped>
@import '@/styles/variables.scss';
.user-setpwd {
    width: 357px;
    margin:0 auto;
    padding-top: 40px;
}
.vcode-area {
    height: 40px;
    width: 100px;
    box-sizing: border-box;
    position: absolute;
    right: 0px;
    top: 0px;
}
.input-verifycode{
    width: 70%;
    ::v-deep .el-input__inner{
        border: none;
    }
}
.vcode-btn {
    top: 50%;
    transform: translateY(-50%);
    width: 86px;
    text-align: center;
    font-size: 12px;
    position: absolute;
    right: 4px;
    cursor: pointer;
    min-width: 88px;
    display: inline-block;
}
.vcode-btn:after {
    content: " ";
    position: absolute;
    width: 1px;
    height: 28px;
    background: #d8d8d8;
    right: 90px;
    top: 6px;
    height: 25px;
}
</style>